import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableProps,
  TablePagination,
  Box,
  TextField,
  Button
} from '@mui/material';
import { Paginated } from '../slices/paging';

type PaginatedList<T> = Paginated<T> | null | undefined;

interface Column<T> {
  label: string;
  align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
  render: (item: T) => React.ReactNode;
}

interface GenericTableProps<T> extends TableProps {
  columns: Column<T>[];
  data: PaginatedList<T> | T[];
  pagination?: {
    currentPage: number;
    currentLimit: number;
    count: number;
    handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  };
  keyExtractor: (item: T) => string | number;

  searchComponent?: {
    goToNew: () => void;
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

function getData<T>(data: PaginatedList<T> | T[]): T[] {
  if (data !== undefined && data !== null && (Array.isArray(data) || Array.isArray(data.items))) {
    if ('items' in data) {
      return data.items;
    } else {
      // console.log('WIERD', data);
      // console.log('WIERD', typeof data);
      return data;
    }
  } else {
    return [];
  }
}

const GenericTable = <T,>({
  columns,
  data,
  keyExtractor,
  pagination,
  searchComponent,
  title,
  ...rest
}: GenericTableProps<T>) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '100w', // Ancho completo de la ventana
          //con el valor '100vw', toma todo el ancho
          left: '50%', // Posicionar a la izquierda del centro
          transform: 'translateX(-50%)', // Alinear correctamente
          backgroundColor: '#b3e5fc', // Color celeste claro
          padding: '10px 0', // Espaciado arriba y abajo
          textAlign: 'center', // Centrar el texto
          marginBottom: '20px' // Separar del contenido de abajo
        }}>
        <h2 style={{ margin: 0, fontWeight: 'bold', color: '#0277bd' }}>{title}</h2>
      </Box>

      {searchComponent ? (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <TextField
            label="Buscar"
            variant="outlined"
            fullWidth
            size="small" // Makes the TextField smaller
            onChange={searchComponent.handleSearchChange}
            sx={{ marginRight: 2, padding: 0 }} // Adjust padding to match the Button height
          />
          <Button
            variant="outlined"
            color="success"
            onClick={searchComponent.goToNew}
            sx={{
              whiteSpace: 'nowrap',
              paddingX: 2,
              height: '40px' // Force button height to match TextField if necessary
            }}>
            Nuevo
          </Button>
        </Box>
      ) : null}

      <TableContainer component={Paper}>
        <Table {...rest}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align || 'left'}
                  style={{ fontWeight: 'bold' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getData(data).map((item) => (
              <TableRow
                key={keyExtractor(item)}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, py: 1 }}>
                {columns.map((column, index) => (
                  <TableCell key={index} align={column.align || 'left'}>
                    {column.render(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination !== undefined ? (
        <TablePagination
          component="div"
          count={pagination.count ?? 100}
          page={pagination.currentPage - 1}
          onPageChange={pagination.handlePageChange}
          rowsPerPage={pagination.currentLimit}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        />
      ) : null}
    </Box>
  );
};

export default GenericTable;
