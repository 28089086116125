import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import clientReducer from './slices/clientSlice';
import productReducer from './slices/productSlice';
import orderReducer from './slices/orderSlice';
import notificationReducer from './slices/notificationSlice';
import constantReducer from './slices/constantSlice';
import machineReducer from './slices/machineSlice';
import metricReducer from './slices/metricsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    notification: notificationReducer,
    client: clientReducer,
    product: productReducer,
    order: orderReducer,
    constant: constantReducer,
    machine: machineReducer,
    metric: metricReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
