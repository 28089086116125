import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import DefaultLayout from './layouts/DefaultLayout';
import ProtectedLayout from './layouts/ProtectedLayout';
import NotificationBar from './components/notification/NotificationBar';
import Client from './pages/Client';
import Product from './pages/Product';
import Order from './pages/Order';
import Machine from './pages/Machine';
import ClientList from './pages/ClientList';
import ProductList from './pages/ProductList';
import OrderList from './pages/OrderList';
import MachineList from './pages/MachineList';
import PDF from './pages/PDF';
import ProtectedDefautLayout from './layouts/ProtectedDefaultLayout';
import UserList from './pages/UserList';

function App() {
  return (
    <>
      <NotificationBar />
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route element={<ProtectedDefautLayout />}>
          <Route path="/pdf/:orderId" element={<PDF />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/users" element={<UserList />} />
          <Route path="/clients" element={<ClientList />} />
          <Route path="/clients/:clientId" element={<Client />} />
          <Route path="/clients/new" element={<Client />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:productId" element={<Product />} />
          <Route path="/products/new" element={<Product />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/orders/new" element={<Order />} />
          <Route path="/machines" element={<MachineList />} />
          <Route path="/machines/new" element={<Machine />} />
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
