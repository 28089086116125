import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export default function CustomSelect({
  value,
  list,
  label,
  name,
  onChange
}: {
  value: string;
  list: { key: string; name: number | string }[];
  label: string;
  name: string;
  onChange: (event: SelectChangeEvent) => void;
}) {
  const rand = uuidv4();
  const id = `${label}-${rand}`;
  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        required
        label={label}
        id={name}
        name={name}
        value={value}
        onChange={onChange}>
        {list.map((val) => (
          <MenuItem key={val.key} value={val.key}>
            {val.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
