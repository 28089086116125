export const logger = {
  info: (m: any, b?: any) => {
    if (b) {
      console.log(m, b);
    } else {
      console.log(m);
    }
  },
  debug: (m: any, b?: any) => {
    if (process.env.NODE_ENV !== 'production') {
      if (b) {
        console.log(m, b);
      } else {
        console.log(m);
      }
    }
  }
};
