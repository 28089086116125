import { Chip } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getConstants } from '../slices/constantSlice';
import GenericContainer from '../layouts/GenericContainer';
import GenericTable from '../layouts/GenericTable';
import { getUsers, searchUsers, setLimit, setPage, User } from '../slices/userSlice';

const UserList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { status } = useAppSelector((state) => state.constant);
  const users = useAppSelector((state) => state.user.users);
  const currentPage = useAppSelector((state) => state.user.page);
  const currentLimit = useAppSelector((state) => state.user.limit);
  const count = useAppSelector((state) => state.user.count);

  useEffect(() => {
    dispatch(
      getUsers({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
    );
    dispatch(getConstants({ navigate }));
  }, [dispatch, navigate, currentPage, currentLimit]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  const columns = [
    {
      label: 'Nombre',
      align: 'center' as const,
      render: (user: User) => user.firstName
    },
    {
      label: 'Apellido',
      align: 'center' as const,
      render: (user: User) => user.lastName
    },
    {
      label: 'email',
      align: 'center' as const,
      render: (user: User) => user.email
    },
    {
      label: 'Rol',
      align: 'center' as const,
      render: (user: User) => getCorrectStatusChip(user.role)
    }
  ];

  const getCorrectStatusChip = (status: string) => {
    if (status === 'EMPLOYEE') {
      return <Chip label={status} color="primary" />;
    } else {
      return <Chip label={status} color="success" />;
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      dispatch(
        getUsers({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
      );
    } else {
      dispatch(searchUsers({ options: { word: event.target.value }, navigate }));
    }
  };

  const goToNew = () => {
    navigate('/users/new');
  };

  return (
    <>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {userToUpdate?.internal_batch ?? ''} - {userToUpdate?.purchase_user ?? ''}
          </Typography>
          <br></br>
          {CustomSelect({
            value: userToUpdate?.status ?? '',
            label: 'Estado',
            list: constants?.user.status.map((c) => ({ key: c, name: c })) ?? [],
            name: 'status',
            onChange: handleUserToUpdateChange
          })}

          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleUserUpdate}>
            Actualizar
          </Button>
        </Box>
      </Modal> */}

      <GenericContainer status={status}>
        <GenericTable
          columns={columns}
          data={users}
          keyExtractor={(user) => user.user_id}
          pagination={{
            currentPage,
            currentLimit,
            handlePageChange,
            handleChangeRowsPerPage,
            count
          }}
          searchComponent={{
            goToNew,
            handleSearchChange
          }}
          title="Listado de usuarios"
        />
      </GenericContainer>
    </>
  );
};

export default UserList;
