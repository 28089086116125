import axios, { isAxiosError } from 'axios';
import { logout } from '../slices/authSlice';
import { NavigateFunction } from 'react-router-dom';
import { logger } from './logger';

export type PostEndpointInjector<T> = {
  body: T;
  navigate: NavigateFunction;
};

export type PutEndpointInjector<T> = {
  body: T;
  id: string;
  navigate: NavigateFunction;
};

export type GetEndpointInjector<T> = {
  options?: T | undefined;
  navigate: NavigateFunction;
};

export type GetOneEndpointInjector = {
  id: string;
  navigate: NavigateFunction;
};

export type DeletendpointInjector = {
  id: string;
  navigate: NavigateFunction;
};

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

httpClient.interceptors.request.use(
  (config) => {
    logger.debug(`Request: ${(config.method ?? '').toUpperCase()}`, config.url);
    if (config.method !== 'get') {
      logger.debug('Body:', config.data);
    }
    // Do something before request is sent
    if (!config.url?.includes('login')) {
      const token = getAccessToken(); // Example: Get token from local storage
      if (token) {
        config.headers['access_token'] = token;
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    logger.debug('Request error:', error);
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    // Do something with response data
    logger.debug('Response:', response.data);
    return response;
  },
  async (error) => {
    if (isAxiosError(error) && error.response) {
      logger.debug(error.response.data);
    }
    return Promise.reject(error);
  }
);

export default httpClient;
