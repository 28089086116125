import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient from '../api/httpClient';
import { NotificationType, showNotification } from './notificationSlice';
import { AxiosError } from 'axios';

type User = {
  email: string;
  password: string;
};

type UserBasicInfo = {
  user_id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: 'ADMIN' | 'EMPLOYEE';
};

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const login = createAsyncThunk(
  'login',
  async (data: User, { rejectWithValue, dispatch }) => {
    try {
      const response = await httpClient.post('/login', data);
      const resData = response.data;

      sessionStorage.setItem('userInfo', JSON.stringify(resData));

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        dispatch(
          showNotification({
            message: errorResponse.message,
            type: NotificationType.Error
          })
        );
        return rejectWithValue(errorResponse);
      }
      dispatch(
        showNotification({
          message: 'An error occurred',
          type: NotificationType.Error
        })
      );

      throw error;
    }
  }
);

export const logout = createAsyncThunk('logout', async () => {
  sessionStorage.removeItem('userInfo');
  console.log('Done');
});

export const getUser = createAsyncThunk('users/profile', async (userId: string) => {
  const accessToken = getAccessToken();
  const response = await httpClient.get(`/users/${userId}?page=1&limit=3`, {
    headers: { access_token: accessToken }
  });
  return response.data;
});

type LoginRes = { id: string; user: UserBasicInfo; access_token: string };

type ErrorResponse = {
  message: string;
};

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  userProfileData?: UserBasicInfo | null;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: AuthApiState = {
  basicUserInfo: sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo') as string)
    : null,
  userProfileData: undefined,
  status: 'idle',
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<LoginRes>) => {
        state.status = 'idle';
        state.basicUserInfo = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(logout.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = 'idle';
        state.basicUserInfo = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Logout failed';
      })

      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userProfileData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Get user profile data failed';
      });
  }
});

export default authSlice.reducer;
