import { useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';

type Input = {
  title?: string;
  description?: string;
  handleConfirm: () => void;
  children: (showDialog: () => void) => React.ReactNode;
};

function ConfirmationDialog({
  title = 'Confirma que queires eliminar este elemento',
  description = 'Una vez eliminado, no podrá ser recuperado.',
  handleConfirm,
  children
}: Input) {
  const [open, setOpen] = useState(false);

  const showDialog = () => {
    setOpen(true);
  };

  const hideDialog = () => {
    setOpen(false);
  };

  const confirmRequest = () => {
    handleConfirm();
    hideDialog();
  };

  return (
    <>
      {children(showDialog)}
      {open && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={open}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
            <Button onClick={confirmRequest} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ConfirmationDialog;
