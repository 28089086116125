import { TextField, Box, Autocomplete } from '@mui/material';

export interface Option {
  id: string;
  label: string;
}

type FilterableSelectI = {
  options: Option[];
  label: string;
  textInputOnChange: (value: string) => void;
  selectOnChange: (option: Option | null) => void;
  // helperText: string | undefined;
};

const FilterableSelect = ({
  options,
  label,
  textInputOnChange,
  selectOnChange
}: FilterableSelectI) => {
  return (
    <Box display="flex" gap={1} width="100%">
      <Autocomplete
        disablePortal
        options={options}
        onChange={(event, newValue) => {
          selectOnChange(newValue);
        }}
        onInputChange={(e, newVal) => {
          textInputOnChange(newVal);
        }}
        sx={{ flex: 1 }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      {/* <TextField
        label={label}
        variant="outlined"
        value={textInputValue}
        onChange={textInputOnChange}
        margin="dense"
        sx={{ flex: 1 }}
      />
      <Select
        value={selectedValue}
        onChange={selectOnChange}
        displayEmpty
        variant="outlined"
        sx={{ flex: 1, height: '56px', marginTop: '8px' }}>
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options</MenuItem>
        )}
      </Select> */}
    </Box>
  );
};

export default FilterableSelect;
