import { useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { getUser } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { getMetrics } from '../slices/metricsSlice';

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);
  const metrics = useAppSelector((state) => state.metric.metrics);

  useEffect(() => {
    if (basicUserInfo) {
      dispatch(getUser(basicUserInfo.user_id));
    }
    dispatch(getMetrics({ navigate }));
  }, [dispatch, navigate, basicUserInfo]);

  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            maxWidth: 1100,
            margin: '0 auto',
            padding: 2,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardHeader title="Clientes" />
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" height="50px">
                    <Typography variant="h2" color="green">
                      {metrics?.clients_count}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardHeader title="Productos" />
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" height="50px">
                    <Typography variant="h2" color="green">
                      {metrics?.products_count}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardHeader title="Ordenes activas" />
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" height="50px">
                    <Typography variant="h2" color="green">
                      {metrics?.active_orders_count}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardHeader title="Ordenes completas" />
                <CardContent>
                  <Box display="flex" alignItems="center" justifyContent="center" height="50px">
                    <Typography variant="h2" color="green">
                      {metrics?.completed_orders_count}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Home;
