import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, {
  GetEndpointInjector,
  GetOneEndpointInjector,
  PostEndpointInjector,
  PutEndpointInjector
} from '../api/httpClient';
import { logout } from './authSlice';
import { Options, Paginated, buildPagingOptions } from './paging';
import { handleErrorResponse } from '../utils/utils';
import { ClientGet } from './clientSlice';

export type ProductAttributes = {
  name: string;
  material: string;
  color: string;
  mother_coil_width: number;
  print_type: string;
  art_format: number;
  x: number;
  amount_of_colors: number;
  machine: {
    name: string;
    // stuck: string;
    // cylinder: number;
    // cylinder_extra: number;
    // troquel_num: number;
    // clise_num: number;
    machine_id: string;
    module: {
      color: string;
      color_num: string;
      color_extra: string;
    };
  };
  labels_per_roll: number;
  labels_per_full_box: number;
  size: {
    width: number;
    height: number;
  };
  position: number;
  client_id?: string;
};

type ProductBase = {
  name: string;
  attributes: {
    material: string;
    color: string;
    mother_coil_width: number;
    print_type: string;
    art_format: number;
    x: number;
    amount_of_colors: number;
    machine: {
      module: {
        color: string;
        color_num: string;
        color_extra: string;
      };
    };
    labels_per_roll: number;
    labels_per_full_box: number;
    size: {
      width: number;
      height: number;
    };
    position: number;
  };
  machine: {
    name: string;
    stuck: string;
    cylinder: number;
    cylinder_extra: number;
    troquel_num: number;
    clise_num: number;
    machine_id: string;
  };
  client?: ClientGet;
};

export type ProductGet = {
  product_id: string;
} & ProductBase;

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const postProduct = createAsyncThunk(
  'products/post',
  async (product: PostEndpointInjector<ProductAttributes>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/products`, product.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: product.navigate });
      return err;
    }
  }
);

export const putProduct = createAsyncThunk(
  'products/put',
  async (product: PutEndpointInjector<ProductAttributes>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.put(`/products/${product.id}`, product.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: product.navigate });
      return err;
    }
  }
);

export const getProducts = createAsyncThunk(
  'products/get/all',
  async (deps: GetEndpointInjector<Options>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/products?${buildPagingOptions(deps.options)}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getProductsByClient = createAsyncThunk(
  'products/get/mul/by/client',
  async (deps: GetEndpointInjector<{ product_id: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/products?client_id=${deps.options?.product_id}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const searchProducts = createAsyncThunk(
  'products/search',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/products?search_word=${deps.options?.word ?? ''}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getProduct = createAsyncThunk(
  'products/get',
  async (deps: GetOneEndpointInjector, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/products/${deps.id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

type ErrorResponse = {
  message: string;
};

type ProductApiState = {
  products?: ProductGet[] | null;
  product?: ProductGet | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: ProductApiState = {
  products: undefined,
  status: 'idle',
  page: 1,
  count: 0,
  limit: 10,
  error: null
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.products = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.products = undefined;
    },
    resetProductState(state) {
      state.product = undefined;
      state.products = undefined;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action: PayloadAction<Paginated<ProductGet>>) => {
        state.status = 'idle';
        state.products = action.payload.items;
        state.count = action.payload.count;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getProductsByClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getProductsByClient.fulfilled, (state, action: PayloadAction<ProductGet[]>) => {
        state.status = 'idle';
        state.products = action.payload;
      })
      .addCase(getProductsByClient.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchProducts.fulfilled, (state, action: PayloadAction<ProductGet[]>) => {
        state.status = 'idle';
        state.products = action.payload;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getProduct.fulfilled, (state, action: PayloadAction<ProductGet>) => {
        state.status = 'idle';
        state.product = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postProduct.fulfilled, (state, action) => {
        state.status = 'idle';
        state.product = undefined;
        state.products = undefined;
      })
      .addCase(postProduct.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(putProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putProduct.fulfilled, (state, action) => {
        state.status = 'idle';
        state.product = undefined;
        state.products = undefined;
      })
      .addCase(putProduct.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = productSlice.actions;
export const { setLimit } = productSlice.actions;
export const { resetProductState } = productSlice.actions;
export default productSlice.reducer;
