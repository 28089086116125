import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import {
  ClientGet,
  getClients,
  setPage,
  setLimit,
  searchClients,
  resetClientState
} from '../slices/clientSlice';
import { useNavigate } from 'react-router-dom';
import GenericContainer from '../layouts/GenericContainer';
import GenericTable from '../layouts/GenericTable';
import GenericIconButton from '../layouts/GenericIconButton';
import CreateIcon from '@mui/icons-material/Create';

const ClientList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { clients, status } = useAppSelector((state) => state.client);
  const currentLimit = useAppSelector((state) => state.client.limit);
  const currentPage = useAppSelector((state) => state.client.page);
  const count = useAppSelector((state) => state.client.count);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  useEffect(() => {
    dispatch(
      getClients({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
    );

    return () => {
      dispatch(resetClientState());
    };
  }, [dispatch, navigate, currentPage, currentLimit]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const goToEdit = (id: string) => () => {
    navigate(`/clients/${id}`);
  };

  const columns = [
    // {
    //   label: 'Id',
    //   render: (client: ClientGet) => client.client_id
    // },
    {
      label: 'Código',
      render: (client: ClientGet) => client.code
    },
    {
      label: 'Nombre',
      render: (client: ClientGet) => client.name
    },
    {
      label: 'Tipo de documento',
      render: (client: ClientGet) => client.gov_id_type
    },
    {
      label: 'Documento',
      render: (client: ClientGet) => client.gov_id
    },

    {
      label: 'Action',
      align: 'center' as const,
      render: (client: ClientGet) => (
        <GenericIconButton onClick={goToEdit(client.client_id)}>
          <CreateIcon fontSize="inherit" />
        </GenericIconButton>
      )
    }
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      dispatch(
        getClients({ options: { paging: { page: currentPage, limit: currentLimit } }, navigate })
      );
    } else {
      dispatch(searchClients({ options: { word: event.target.value }, navigate }));
    }
  };

  const goToNew = () => {
    navigate('/clients/new');
  };

  return (
    <GenericContainer status={status}>
      <GenericTable
        columns={columns}
        data={clients}
        keyExtractor={(client) => client.client_id}
        pagination={{
          currentPage,
          currentLimit,
          handlePageChange,
          handleChangeRowsPerPage,
          count
        }}
        searchComponent={{
          goToNew,
          handleSearchChange
        }}
        title="Listado de clientes"
      />
    </GenericContainer>
  );
};

export default ClientList;
