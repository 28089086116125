import React, { useState, MouseEvent, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { logout } from '../slices/authSlice';
import { Accordion, AccordionDetails, AccordionSummary, MenuList } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { whoAmI } from '../slices/userSlice';

const pages: { name: string; list: { name: string; to: string }[]; to: string }[] = [
  {
    name: 'Usuarios',
    list: [
      // {
      //   name: 'Ver todos',
      //   to: '/users'
      // }
    ],
    to: '/users'
  },
  {
    name: 'Clientes',
    list: [
      // {
      //   name: 'Ver todos',
      //   to: '/clients'
      // }
    ],
    to: '/clients'
  },
  {
    name: 'Productos',
    list: [
      // {
      //   name: 'Ver todos',
      //   to: '/products'
      // }
    ],
    to: '/products'
  },
  {
    name: 'Ordenes',
    list: [
      // {
      //   name: 'Ver todos',
      //   to: '/orders'
      // }
    ],
    to: '/orders'
  },
  {
    name: 'Maquinas',
    list: [
      // {
      //   name: 'Ver todos',
      //   to: '/machines'
      // }
    ],
    to: '/machines'
  }
];

const settings = [
  // {
  //   name: 'Profile',
  //   to: '/'
  // },
  {
    name: 'Logout',
    to: '/'
  }
];

const MyAppBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const location = useLocation();

  const basicUserInfo = useAppSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(whoAmI({ navigate }));
  }, [dispatch, navigate]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>, pageName: string) => {
    setAnchorElNav(event.currentTarget);
    setOpenMenu(pageName);
  };

  const handleClose = () => {
    setAnchorElNav(null);
    setOpenMenu(null);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      navigate('/login');
    } catch (e) {
      console.error(e);
    }
  };

  const filteredPages = pages.filter((page) => {
    if (page.name === 'Usuarios') {
      return basicUserInfo?.role === 'ADMIN';
    }
    return true;
  });

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            IMPRENTA
          </Typography>

          {/* Permite ver en version mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}>
              {filteredPages.map((page) => (
                <Accordion key={page.name}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${page.name}-content`}
                    id={`${page.name}-header`}>
                    <Typography>{page.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MenuList>
                      {page.list.map((p) => (
                        <MenuItem
                          key={p.name}
                          component={Link}
                          onClick={handleCloseNavMenu}
                          to={p.to}>
                          <Typography textAlign="center">{p.name}</Typography>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Menu>
          </Box>

          {/* Permite ver en version desktop */}
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            IMPRENTA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {filteredPages.map((page) => (
              <div key={page.name}>
                {page.list.length > 0 ? (
                  <>
                    <Button
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, page.name)}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        backgroundColor: page.list.some(
                          (item) => item.to === `/${location.pathname.split('/')[1]}`
                        )
                          ? 'rgba(255, 255, 255, 0.2)'
                          : openMenu === page.name
                          ? 'rgba(255, 255, 255, 0.3)'
                          : 'transparent'
                      }}>
                      {page.name}
                    </Button>
                    <Menu
                      anchorEl={anchorElNav}
                      open={openMenu === page.name}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}>
                      {page.list.map((item) => (
                        <MenuItem
                          key={item.name}
                          component={Link}
                          to={item.to}
                          onClick={handleClose}
                          sx={{ fontSize: '0.875rem' }}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  <Button
                    component={Link}
                    to={page.to || `/${page.name.toLowerCase()}`}
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      backgroundColor:
                        (page.to || `/${page.name.toLowerCase()}`) ===
                        `/${location.pathname.split('/')[1]}`
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'transparent'
                    }}>
                    {page.name}
                  </Button>
                )}
              </div>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={
                    basicUserInfo ? basicUserInfo.firstName + ' ' + basicUserInfo.lastName : 'Unk'
                  }
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={handleLogout}>
                  <Typography
                    textAlign="center"
                    component={Link}
                    to={setting.to}
                    sx={{
                      mr: 2,
                      color: 'inherit',
                      textDecoration: 'none'
                    }}>
                    {setting.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyAppBar;
