import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { Machine, deleteMachine, getMachines, setLimit, setPage } from '../slices/machineSlice';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from './library/ConfirmationDialog';
import GenericIconButton from '../layouts/GenericIconButton';
import GenericContainer from '../layouts/GenericContainer';
import GenericTable from '../layouts/GenericTable';

const MachineList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { machines, status } = useAppSelector((state) => state.machine);
  const currentLimit = useAppSelector((state) => state.machine.limit);
  const currentPage = useAppSelector((state) => state.machine.page);
  const count = useAppSelector((state) => state.machine.count);

  useEffect(() => {
    dispatch(getMachines({ navigate }));
  }, [dispatch, navigate]);

  const handleIconClick = (machineId: string) => async () => {
    await dispatch(deleteMachine({ id: machineId, navigate })).unwrap();
    await dispatch(getMachines({ navigate }));
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  const columns: {
    label: string;
    align?: 'right';
    render: (machine: Machine) => number | string | JSX.Element;
  }[] = [
    {
      label: 'Nombre',
      render: (machine: Machine) => machine.name
    },
    {
      label: 'Pegado',
      align: 'right' as const,
      render: (machine: Machine) => machine.stuck
    },
    {
      label: 'Cilindro',
      align: 'right' as const,
      render: (machine: Machine) => machine.cylinder
    },
    {
      label: 'Extra',
      align: 'right' as const,
      render: (machine: Machine) => machine.cylinder_extra
    },
    {
      label: 'Troquel',
      align: 'right' as const,
      render: (machine: Machine) => machine.troquel_num
    },
    {
      label: 'Clise',
      align: 'right' as const,
      render: (machine: Machine) => machine.clise_num
    },
    {
      label: 'Actions',
      align: 'right' as const,
      render: (machine: Machine) => (
        <ConfirmationDialog handleConfirm={handleIconClick(machine.machine_id)}>
          {(showDialog) => (
            <GenericIconButton onClick={showDialog}>
              <HighlightOffIcon fontSize="inherit" />
            </GenericIconButton>
          )}
        </ConfirmationDialog>
      )
    }
  ];

  return (
    <GenericContainer status={status}>
      <GenericTable
        columns={columns}
        data={machines}
        keyExtractor={(machine) => machine.machine_id}
        pagination={{
          currentPage,
          currentLimit,
          handlePageChange,
          handleChangeRowsPerPage,
          count
        }}
        title="Listado de maquinas"
      />
    </GenericContainer>
  );
};

export default MachineList;
