import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

interface GenericContainerProps extends BoxProps {
  maxWidth?: number;
  minWidth?: number;
  padding?: number;
  margin?: string;
  children: React.ReactNode;
  status?: 'loading' | 'idle' | 'failed';
}

const GenericContainer: React.FC<GenericContainerProps> = ({
  maxWidth = 1200,
  minWidth = 100,
  padding = 2,
  margin = '0 auto',
  status,
  children,
  ...rest
}) => (
  <>
    {status === 'loading' ? (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(128, 128, 128, 0.5)', // Grey with 50% opacity
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999 // Ensure it overlays everything
        }}>
        <CircularProgress />
      </Box>
    ) : (
      <></>
    )}
    <Box
      sx={{
        maxWidth: maxWidth,
        minWidth: minWidth,
        margin: margin,
        padding: padding
      }}
      {...rest}>
      {children}
    </Box>
  </>
);

export default GenericContainer;
