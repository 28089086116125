import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import { logout } from '../slices/authSlice';
import { NavigateFunction } from 'react-router-dom';
import { pipe } from 'fp-ts/lib/function';

export const getMessage = (e: any) => {
  console.log(e);

  if (isAxiosError(e) && e.response?.data) {
    return e.response.data.message;
  }

  return 'Something went wrong';
};

export const handleErrorResponse = async ({
  error,
  dispatch,
  navigate
}: {
  error: unknown;
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>;
  navigate: NavigateFunction;
}) => {
  if (isAxiosError(error) && error.response) {
    console.log('Axios found');

    if (
      (error.response.status && error.response.status === 401) ||
      error.response.data.error_message === 'User not found'
    ) {
      console.log('401 found');

      sessionStorage.removeItem('userInfo');
      await dispatch(logout()).unwrap();
      navigate('/login');
    }

    console.log(error.response.data);
    const errorResponse = error.response.data;
    dispatch(
      showNotification({
        message: errorResponse.message,
        type: NotificationType.Error
      })
    );
    return {
      type: 'ERROR',
      data: error.response.data
    };
  }
  dispatch(
    showNotification({
      message: 'An error occurred',
      type: NotificationType.Error
    })
  );

  throw error;
};

const ValidationErrorC = t.type({
  data: t.type({
    data: t.array(
      t.type({
        key: t.string,
        message: t.string
      })
    )
  })
});

export type ValidationError = t.TypeOf<typeof ValidationErrorC>;

export const isErrorStateValidationErrorType = (smt: unknown): smt is ValidationError =>
  pipe(
    smt,
    ValidationErrorC.decode,
    E.map((_) => true),
    E.getOrElse((err) => false)
  );

export const getValueWithCorrectType = (val: string) => {
  const num = Number(val);
  if (!Number.isNaN(num)) {
    console.log(num);

    return num;
  }
  console.log(val);

  return val;
};

type GenericErrorState = {
  [key: string]: { error: boolean; message: string | undefined } | GenericErrorState;
};

export const updatePropertyByPath = <T extends GenericErrorState>(
  obj: T,
  path: string,
  value: { error: boolean; message: string | undefined }
): T => {
  const keys = path.split('.');
  const keysWithoutNumber = keys.filter((k) => {
    const n = Number.parseInt(k);
    return Number.isNaN(n);
  });

  console.log(keysWithoutNumber);

  const objValResult: GenericErrorState = keysWithoutNumber.reduce(
    (acc: GenericErrorState, key: string, index: number) => {
      if (acc[key] !== undefined) {
        return acc[key] as GenericErrorState;
      } else {
        return {};
      }
    },
    obj
  );
  console.log(objValResult);
  if (objValResult !== undefined && 'error' in objValResult) {
    if (keysWithoutNumber.length === 1) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: value
      };
    } else if (keysWithoutNumber.length === 2) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: value
        }
      };
    } else if (keysWithoutNumber.length === 3) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: {
            ...(obj[keysWithoutNumber[0]] as GenericErrorState)[keysWithoutNumber[1]],
            [keysWithoutNumber[2]]: value
          }
        }
      };
    } else if (keysWithoutNumber.length === 4) {
      return {
        ...obj,
        [keysWithoutNumber[0]]: {
          ...obj[keysWithoutNumber[0]],
          [keysWithoutNumber[1]]: {
            ...(obj[keysWithoutNumber[0]] as GenericErrorState)[keysWithoutNumber[1]],
            [keysWithoutNumber[2]]: {
              ...(
                (obj[keysWithoutNumber[0]] as GenericErrorState)[
                  keysWithoutNumber[1]
                ] as GenericErrorState
              )[keysWithoutNumber[2]],
              [keysWithoutNumber[3]]: value
            }
          }
        }
      };
    } else {
      return obj;
    }
  } else {
    return obj;
  }
};

// const mergeDifferentProperties = <T extends GenericErrorState>(a: T, b: T): GenericErrorState => {
//   // Helper function to perform the merge
//   function mergeObjects(objA: GenericErrorState, objB: GenericErrorState): void {
//     for (const key in objB) {
//       if (objB.hasOwnProperty(key)) {
//         const valueA = objA[key];
//         const valueB = objB[key];

//         if (
//           typeof valueB === 'object' &&
//           valueB !== null &&
//           typeof valueA === 'object' &&
//           valueA !== null
//         ) {
//           // Recursively merge nested objects
//           mergeObjects(valueA as GenericErrorState, valueB as GenericErrorState);
//         } else if (valueA !== valueB) {
//           // Update only if different
//           objA[key] = valueB;
//         }
//       }
//     }
//   }

//   // Create a deep copy of `a` to avoid mutating the original object
//   const result = JSON.parse(JSON.stringify(a)) as T;

//   // Perform the merge
//   mergeObjects(result, b);

//   return result;
// };
