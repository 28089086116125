import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const formatDate = (isoString: string): string => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(tz);
  const localDate = toZonedTime(isoString, tz, { timeZone: 'UTC' });
  return format(localDate, 'HH:mm:ss dd/MM/yyyy');
};

export default formatDate;
