import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, {
  DeletendpointInjector,
  GetEndpointInjector,
  PostEndpointInjector
} from '../api/httpClient';
import { logout } from './authSlice';
import { Options, Paginated, buildPagingOptions } from './paging';
import { handleErrorResponse } from '../utils/utils';

export type MachineBase = {
  name: string;
  stuck: string;
  cylinder: number;
  cylinder_extra: number;
  troquel_num: number;
  clise_num: number;
};

export type Machine = {
  machine_id: string;
} & MachineBase;

export const valid = (machine: { [x: string]: string | number }) => {
  const keys = Object.keys(machine);
  let res = true;
  keys.forEach((k) => {
    if (Number.isInteger(machine[k])) {
      res = res && machine[k] !== 0;
    } else {
      res = res && machine[k] !== '';
    }
  });

  return res;
};

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const postMachine = createAsyncThunk(
  'machines-p',
  async (item: PostEndpointInjector<MachineBase>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/machines`, item.body, {
        headers: { access_token: accessToken }
      });

      return response.data;
    } catch (error) {
      const errorResponse = await handleErrorResponse({ error, dispatch, navigate: item.navigate });
      return errorResponse;
    }
  }
);

export const deleteMachine = createAsyncThunk(
  'machines-d',
  async (item: DeletendpointInjector, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.delete(`/machines/${item.id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      console.log(err);
      return err;
    }
  }
);

export const getMachines = createAsyncThunk(
  'machines',
  async (item: GetEndpointInjector<Options>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/machines?${buildPagingOptions(item.options)}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      return err;
    }
  }
);

type ErrorResponse = {
  message: string;
};

type MachineApiState = {
  machines?: Paginated<Machine> | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: MachineApiState = {
  machines: undefined,
  status: 'idle',
  page: 1,
  limit: 10,
  count: 0,
  error: null
};

const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.machines = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.machines = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMachines.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getMachines.fulfilled, (state, action: PayloadAction<Paginated<Machine>>) => {
        state.status = 'idle';
        state.machines = action.payload;
        state.count = action.payload.count;
      })
      .addCase(getMachines.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postMachine.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postMachine.fulfilled, (state, action) => {
        state.status = 'idle';
        state.machines = undefined;
      })
      .addCase(postMachine.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(deleteMachine.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteMachine.fulfilled, (state, action) => {
        state.status = 'idle';
        state.machines = undefined;
      })
      .addCase(deleteMachine.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = machineSlice.actions;
export const { setLimit } = machineSlice.actions;
export default machineSlice.reducer;
