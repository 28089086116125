import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpConstant, { GetEndpointInjector } from '../api/httpClient';
import { logout } from './authSlice';
import { handleErrorResponse } from '../utils/utils';
import { Options } from './paging';

export type Constant = {
  order: {
    status: string[];
  };
};

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const getConstants = createAsyncThunk(
  'constants/get',
  async (item: GetEndpointInjector<Options>, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpConstant.get(`/constants`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      rejectWithValue(err);
    }
  }
);

type ErrorResponse = {
  message: string;
};

type ConstantApiState = {
  constants?: Constant | null;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: ConstantApiState = {
  constants: undefined,
  status: 'idle',
  error: null
};

const constantSlide = createSlice({
  name: 'constant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConstants.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getConstants.fulfilled, (state, action: PayloadAction<Constant>) => {
        state.status = 'idle';
        state.constants = action.payload;
      })
      .addCase(getConstants.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export default constantSlide.reducer;
