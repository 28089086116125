import { Box, TextField, Button } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import { MachineBase, getMachines, postMachine, valid } from '../slices/machineSlice';
import { useNavigate } from 'react-router-dom';
import { isErrorStateValidationErrorType, updatePropertyByPath } from '../utils/utils';

const Machine = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emptyMachine: MachineBase = {
    name: '',
    stuck: '',
    cylinder: 0,
    cylinder_extra: 0,
    troquel_num: 0,
    clise_num: 0
  };

  const emptyMachineErrorMessageState = {
    name: { error: false, message: undefined },
    stuck: { error: false, message: undefined },
    cylinder: { error: false, message: undefined },
    cylinder_extra: { error: false, message: undefined },
    troquel_num: { error: false, message: undefined },
    clise_num: { error: false, message: undefined }
  };

  const [newMachine, setMachine] = useState(emptyMachine);
  const [machineErrorState, setMachineErrorState] = useState(emptyMachineErrorMessageState);

  const machines = useAppSelector((state) => state.machine.machines);

  const handleMachineCreation = async () => {
    if (valid(newMachine)) {
      try {
        const response = await dispatch(postMachine({ body: newMachine, navigate })).unwrap();
        setMachineErrorState(emptyMachineErrorMessageState);
        if (response.type !== 'ERROR') {
          setMachine(emptyMachine);
          navigate('/machines');
        } else {
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setMachineErrorState((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                });
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Complete todos los datos del formulario',
          type: NotificationType.Error
        })
      );
    }
  };

  useEffect(() => {
    if (!machines) {
      dispatch(getMachines({ navigate }));
    }
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    console.log(name);
    console.log(value);

    const paths = name.split('.');
    if (paths.length === 1) {
      setMachine((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? +value : value
      }));
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 1100,
        minWidth: 500,
        margin: '0 auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '50ch' },
          '& .MuiFormControl-root': { m: 1, width: '50ch' }
        }}>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            autoFocus={machineErrorState.name.message !== undefined}
            value={newMachine.name}
            onChange={handleChange}
            helperText={machineErrorState.name.message}
            error={machineErrorState.name.error}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="stuck"
            label="Pegado"
            name="stuck"
            autoFocus={machineErrorState.stuck.message !== undefined}
            type="string"
            value={newMachine.stuck}
            onChange={handleChange}
            helperText={machineErrorState.stuck.message}
            error={machineErrorState.stuck.error}
          />
        </div>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="cylinder"
            label="Cilindro"
            name="cylinder"
            autoFocus
            type="number"
            value={newMachine.cylinder === 0 ? '' : newMachine.cylinder}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="cylinder_extra"
            label="Cilindro (extra)"
            name="cylinder_extra"
            autoFocus
            type="number"
            value={newMachine.cylinder_extra === 0 ? '' : newMachine.cylinder_extra}
            onChange={handleChange}
          />
        </div>
        <div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="troquel_num"
            label="Torquel"
            name="troquel_num"
            autoFocus
            type="number"
            value={newMachine.troquel_num === 0 ? '' : newMachine.troquel_num}
            onChange={handleChange}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="clise_num"
            label="Clise"
            name="clise_num"
            autoFocus
            type="number"
            value={newMachine.clise_num === 0 ? '' : newMachine.clise_num}
            onChange={handleChange}
          />
        </div>

        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleMachineCreation}>
          Crear
        </Button>
      </Box>
    </Box>
  );
};

export default Machine;
