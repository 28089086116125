import { TextField } from '@mui/material';

export default function CustomTextField({
  value,
  label,
  name,
  type,
  onChange,
  helperText,
  error
}: {
  value: string | number;
  label: string;
  name: string;
  type: 'number' | 'string';
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  helperText: string | undefined;
  error: boolean;
}) {
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      id={name}
      label={label}
      name={name}
      type={type}
      value={typeof value === 'number' ? (value === 0 ? '' : value) : value}
      onChange={onChange}
      helperText={helperText}
      error={error}
    />
  );
}
