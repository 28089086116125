import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, { GetEndpointInjector, PostEndpointInjector } from '../api/httpClient';
import { logout } from './authSlice';
import { Options, Paginated, buildPagingOptions } from './paging';
import { handleErrorResponse } from '../utils/utils';

type OrderPost = {
  product_id: string;
  client_id: string;
  purchase_order: string;
  internal_batch: string;
  ordered_in: string;
  quantity: number;
  real_meters: number;
  meters_with_scrup: number;
  scrupt: number;
  quantity_of_lables_per_meter_line: number;
  details: {
    esp: number;
    track: number;
    repetitions: number;
    duplex: number;
    treated: string;
  };
};

type OrderPut = {
  order_id: string;
  status: string;
};

export type OrderGetAll = {
  order_id: string;
  created_at: string;
  status: string;
  product_id: string;
  client_id: string;
  purchase_order: string;
  internal_batch: string;
  ordered_in: string;
  quantity: number;
  real_meters: number;
  meters_with_scrup: number;
  scrupt: number;
  quantity_of_lables_per_meter_line: number;
  details: {
    esp: number;
    track: number;
    repetitions: number;
    duplex: number;
    treated: string;
  };
};

export type OrderGetOne = {
  order_id: string;
  created_at: string;
  status: string;
  product_id: string;
  client_id: string;
  purchase_order: string;
  internal_batch: string;
  ordered_in: string;
  quantity: number;
  real_meters: number;
  meters_with_scrup: number;
  scrupt: number;
  quantity_of_lables_per_meter_line: number;
  details: {
    esp: number;
    track: number;
    repetitions: number;
    duplex: number;
    treated: string;
  };
  client: {
    client_id: string;
    code: string;
    name: string;
    gov_id_type: string;
    gov_id: string;
    company_name: string;
    address: string;
  };
  product: {
    product_id: string;
    name: string;
    attributes: {
      name: string;
      material: string;
      color: string;
      mother_coil_width: number;
      print_type: string;
      art_format: number;
      x: number;
      amount_of_colors: number;
      machine: {
        name: string;
        stuck: string;
        cylinder: number;
        cylinder_extra: number;
        troquel_num: number;
        clise_num: number;
        module: {
          color: string;
          color_num: string;
          color_extra: string;
        };
      };
      labels_per_roll: number;
      labels_per_full_box: number;
      size: {
        width: number;
        height: number;
      };
      position: number;
    };
  };
};

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const postOrder = createAsyncThunk(
  'orders-p',
  async (order: PostEndpointInjector<OrderPost>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/orders`, order.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: order.navigate });
      return err;
    }
  }
);

export const putOrder = createAsyncThunk(
  'orders-p',
  async (order: PostEndpointInjector<OrderPut>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.put(`/orders/${order.body.order_id}`, order.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: order.navigate });
      return err;
    }
  }
);

export const searchOrders = createAsyncThunk(
  'search',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/orders?search_word=${deps.options?.word ?? ''}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getOrders = createAsyncThunk('orders', async (options?: Options) => {
  const accessToken = getAccessToken();
  const response = await httpClient.get(`/orders?${buildPagingOptions(options)}`, {
    headers: { access_token: accessToken }
  });
  return response.data;
});

export const getOrder = createAsyncThunk(
  'order',
  async (item: GetEndpointInjector<{ order_id: string }>, { dispatch, rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/orders/${item.options?.order_id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      rejectWithValue(err);
    }
  }
);

type ErrorResponse = {
  message: string;
};

type OrderApiState = {
  orders?: OrderGetAll[] | null;
  order?: OrderGetOne | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: OrderApiState = {
  orders: undefined,
  status: 'idle',
  page: 1,
  limit: 10,
  count: 0,
  error: null
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.orders = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
      state.page = 1;
      state.orders = undefined;
    },
    resetOrderState(state) {
      state.order = undefined;
      state.orders = undefined;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getOrders.fulfilled, (state, action: PayloadAction<Paginated<OrderGetAll>>) => {
        state.status = 'idle';
        state.orders = action.payload.items;
        state.count = action.payload.count;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchOrders.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchOrders.fulfilled, (state, action: PayloadAction<OrderGetAll[]>) => {
        state.status = 'idle';
        state.orders = action.payload;
      })
      .addCase(searchOrders.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postOrder.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orders = undefined;
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getOrder.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getOrder.fulfilled, (state, action: PayloadAction<OrderGetOne>) => {
        state.status = 'idle';
        state.order = action.payload;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { setPage } = orderSlice.actions;
export const { setLimit } = orderSlice.actions;
export const { resetOrderState } = orderSlice.actions;
export default orderSlice.reducer;
