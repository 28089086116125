export type Options = {
  paging?: PaginationOptions;
};

export const buildPagingOptions = (options?: Options) => {
  if (options && options.paging) {
    return `page=${options.paging.page}&limit=${options.paging.limit}`;
  }

  return 'page=1&limit=5';
};

export type PaginationOptions = {
  page: number;
  limit: number;
};

export type Paginated<T> = {
  items: T[];
  page: number;
  limit: number;
  count: number;
};
