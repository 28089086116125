import React from 'react';
import { BoxProps, IconButton } from '@mui/material';

interface GenericIconButtonProps extends BoxProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const GenericIconButton: React.FC<GenericIconButtonProps> = ({ children, onClick }) => {
  return (
    <IconButton size="small" sx={{ width: 18, height: 18 }} onClick={onClick}>
      {children}
    </IconButton>
  );
};

export default GenericIconButton;
