import React from 'react';
import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import ArimoBold from './ArimoBold.ttf';
import ArimoRegular from './ArimoRegular.ttf';

Font.register({
  family: 'Arimo',
  fonts: [{ src: ArimoRegular }, { src: ArimoBold, fontStyle: 'bold', fontWeight: 'bold' }]
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10
  },
  outerTable: {
    borderWidth: 1, // Outer border for the entire table
    borderColor: '#000',
    marginBottom: 10 // Spacing after the table
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1, // Bottom border for rows
    borderColor: '#000'
  },
  lastRow: {
    borderBottomWidth: 0, // No border for the last row
    flexDirection: 'row',
    borderColor: '#000'
  },
  cell: {
    flex: 1,
    borderRightWidth: 1, // Right border for inner cells
    borderColor: '#000',
    padding: 3
  },
  lastCell: {
    borderColor: '#000',
    padding: 3,
    flex: 1,
    borderRightWidth: 0 // No right border for the last cell in a row
  },
  header: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
    fontStyle: 'bold'
  },
  itemTitle: {
    fontSize: 8,
    fontStyle: 'bold',
    fontFamily: 'Arimo'
  },
  itemValue: {
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Arimo'
  }
});

// Props for the table component
interface GenericTableProps {
  data: {
    name: string;
    value: string;
    config?: { removeColon?: boolean; removeSecondText?: boolean };
  }[][]; // 2D array for the row data
}

const getTitle = (val: string, removeColon?: boolean) => {
  if (removeColon === true) {
    return val;
  } else {
    return `${val}:`;
  }
};

// GenericTable Component
const GenericTable: React.FC<GenericTableProps> = ({ data }) => {
  return (
    <View style={styles.outerTable} key={0}>
      {data.map((row, index) =>
        index === data.length - 1 ? (
          <View style={[styles.row, styles.lastRow]} key={`row-${index}`}>
            {row.map((item, itemIndex) =>
              itemIndex === row.length - 1 ? (
                <View style={styles.lastCell} key={`cell-${index}`}>
                  <Text style={styles.itemTitle}>
                    {getTitle(item.name, item.config?.removeColon)}
                  </Text>
                  {item.config?.removeSecondText ? (
                    <></>
                  ) : (
                    <Text style={styles.itemValue}>{item.value}</Text>
                  )}
                </View>
              ) : (
                <View style={styles.cell} key={`cell-${index}`}>
                  <Text style={styles.itemTitle}>
                    {getTitle(item.name, item.config?.removeColon)}
                  </Text>
                  {item.config?.removeSecondText ? (
                    <></>
                  ) : (
                    <Text style={styles.itemValue}>{item.value}</Text>
                  )}
                </View>
              )
            )}
          </View>
        ) : (
          <View style={[styles.row, styles.row]} key={`row-${index}`}>
            {row.map((item, itemIndex) =>
              itemIndex === row.length - 1 ? (
                <View style={styles.lastCell} key={`cell-${index}`}>
                  <Text style={styles.itemTitle}>
                    {getTitle(item.name, item.config?.removeColon)}
                  </Text>
                  {item.config?.removeSecondText ? (
                    <></>
                  ) : (
                    <Text style={styles.itemValue}>{item.value}</Text>
                  )}
                </View>
              ) : (
                <View style={styles.cell} key={`cell-${index}`}>
                  <Text style={styles.itemTitle}>
                    {getTitle(item.name, item.config?.removeColon)}
                  </Text>
                  {item.config?.removeSecondText ? (
                    <></>
                  ) : (
                    <Text style={styles.itemValue}>{item.value}</Text>
                  )}
                </View>
              )
            )}
          </View>
        )
      )}
    </View>
  );
};

export default GenericTable;
