import { Box, Button, SelectChangeEvent, Divider } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { NotificationType, showNotification } from '../slices/notificationSlice';
import { getOrders, postOrder, setPage, setLimit, resetOrderState } from '../slices/orderSlice';
import { getProducts, getProductsByClient } from '../slices/productSlice';
import { getClients } from '../slices/clientSlice';
import CustomTextField from './library/CustomTextField';
import CustomSelect from './library/CustomSelect';
import { useNavigate } from 'react-router-dom';
import { isErrorStateValidationErrorType, updatePropertyByPath } from '../utils/utils';

const Order = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emptyOrder = {
    product_id: '',
    client_id: '',
    purchase_order: '',
    internal_batch: '',
    ordered_in: '',
    quantity: 0,
    real_meters: 0,
    meters_with_scrup: 0,
    scrupt: 0,
    quantity_of_lables_per_meter_line: 0,
    details: {
      esp: 0,
      track: 0,
      repetitions: 0,
      duplex: 0,
      treated: ''
    }
  };

  const emptyOrderErrorMessageState = {
    product_id: { error: false, message: undefined },
    client_id: { error: false, message: undefined },
    purchase_order: { error: false, message: undefined },
    internal_batch: { error: false, message: undefined },
    ordered_in: { error: false, message: undefined },
    quantity: { error: false, message: undefined },
    real_meters: { error: false, message: undefined },
    meters_with_scrup: { error: false, message: undefined },
    scrupt: { error: false, message: undefined },
    quantity_of_lables_per_meter_line: { error: false, message: undefined },
    details: {
      esp: { error: false, message: undefined },
      track: { error: false, message: undefined },
      repetitions: { error: false, message: undefined },
      duplex: { error: false, message: undefined },
      treated: { error: false, message: undefined }
    }
  };

  const [newOrder, setNewOrder] = useState(emptyOrder);
  const [orderErrorState, setOrderErrorState] = useState(emptyOrderErrorMessageState);

  const clients = useAppSelector((state) => state.client.clients);
  const products = useAppSelector((state) => state.product.products);
  const orders = useAppSelector((state) => state.order.orders);
  const currentPage = useAppSelector((state) => state.order.page);
  const currentLimit = useAppSelector((state) => state.order.limit);

  useEffect(() => {
    if (!orders) {
      dispatch(getOrders({ paging: { page: currentPage, limit: currentLimit } }));
    }

    if (!products) {
      dispatch(getProducts({ options: { paging: { page: 1, limit: 30 } }, navigate }));
    }

    if (!clients) {
      dispatch(getClients({ navigate }));
    }
  });

  const handleOrderCreation = async () => {
    if (newOrder.client_id !== undefined && newOrder.client_id !== '') {
      try {
        const response = await dispatch(postOrder({ body: newOrder, navigate })).unwrap();

        if (response.type !== 'ERROR') {
          setOrderErrorState(emptyOrderErrorMessageState);
          setNewOrder(emptyOrder);
          setPage(0);
          setLimit(10);
          navigate('/orders');
        } else {
          setOrderErrorState(emptyOrderErrorMessageState);
          if (isErrorStateValidationErrorType(response)) {
            for (let i = 0; i < response.data.data.length; i++) {
              setOrderErrorState((prevState) => {
                console.log(response.data.data[i]);

                const res = updatePropertyByPath(prevState, response.data.data[i].key, {
                  error: true,
                  message: response.data.data[i].message
                });
                console.log(res);
                return res;
              });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      dispatch(
        showNotification({
          message: 'Please provide all fields',
          type: NotificationType.Error
        })
      );
    }
  };

  const handleSelectClient = async (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    await dispatch(getProductsByClient({ navigate, options: { product_id: value } }));

    handleSelect(e);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);

    const paths = name.split('.');
    if (paths.length === 1) {
      setNewOrder((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else if (paths.length === 2) {
      setNewOrder((prevState) => ({
        ...prevState,
        details: {
          ...prevState.details,
          [paths[1]]: value
        }
      }));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    console.log(name);
    console.log(value);

    const paths = name.split('.');
    if (paths.length === 1) {
      setNewOrder((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? +value : value
      }));
    } else if (paths.length === 2) {
      setNewOrder((prevState) => ({
        ...prevState,
        details: {
          ...prevState.details,
          [paths[1]]: type === 'number' ? +value : value
        }
      }));
    }
  };

  const cancelInsert = () => {
    dispatch(resetOrderState());
    setNewOrder(emptyOrder);
    navigate('/orders');
  };

  return (
    <Box
      sx={{
        maxWidth: 1100,
        minWidth: 500,
        margin: '0 auto',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          '& .MuiFormControl-root': { m: 1, width: '25ch' }
        }}>
        <div>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
            Cliente - Producto
          </Divider>

          {CustomSelect({
            value: newOrder.client_id,
            label: 'Cliente',
            list: clients?.map((c) => ({ key: c.client_id, name: c.name })) ?? [],
            name: 'client_id',
            onChange: handleSelectClient
          })}

          {CustomSelect({
            value: newOrder.product_id,
            label: 'Producto',
            list: products?.map((c) => ({ key: c.product_id, name: c.name })) ?? [],
            name: 'product_id',
            onChange: handleSelect
          })}

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
            Orden
          </Divider>

          {CustomTextField({
            value: newOrder.purchase_order,
            label: 'Orden de compra',
            type: 'string',
            name: 'purchase_order',
            onChange: handleChange,
            helperText: orderErrorState.purchase_order.message,
            error: orderErrorState.purchase_order.error
          })}

          {CustomTextField({
            value: newOrder.internal_batch,
            label: 'Numero de lote',
            type: 'string',
            name: 'internal_batch',
            onChange: handleChange,
            helperText: orderErrorState.internal_batch.message,
            error: orderErrorState.internal_batch.error
          })}
        </div>
        <div>
          {CustomTextField({
            value: newOrder.ordered_in,
            label: 'Unidad de orden',
            type: 'string',
            name: 'ordered_in',
            onChange: handleChange,
            helperText: orderErrorState.ordered_in.message,
            error: orderErrorState.ordered_in.error
          })}

          {CustomTextField({
            value: newOrder.real_meters,
            label: 'Metros reales',
            type: 'number',
            name: 'real_meters',
            onChange: handleChange,
            helperText: orderErrorState.real_meters.message,
            error: orderErrorState.real_meters.error
          })}

          {CustomTextField({
            value: newOrder.meters_with_scrup,
            label: 'Metros con scrup',
            type: 'number',
            name: 'meters_with_scrup',
            onChange: handleChange,
            helperText: orderErrorState.meters_with_scrup.message,
            error: orderErrorState.meters_with_scrup.error
          })}

          {CustomTextField({
            value: newOrder.scrupt,
            label: 'Scrup',
            type: 'number',
            name: 'scrupt',
            onChange: handleChange,
            helperText: orderErrorState.scrupt.message,
            error: orderErrorState.scrupt.error
          })}
        </div>
        <div>
          {CustomTextField({
            value: newOrder.quantity,
            label: 'Cantidad',
            type: 'number',
            name: 'quantity',
            onChange: handleChange,
            helperText: orderErrorState.quantity.message,
            error: orderErrorState.quantity.error
          })}
          {CustomTextField({
            value: newOrder.quantity_of_lables_per_meter_line,
            label: 'Cantidad de etiquetas por metro lineal',
            type: 'number',
            name: 'quantity_of_lables_per_meter_line',
            onChange: handleChange,
            helperText: orderErrorState.quantity_of_lables_per_meter_line.message,
            error: orderErrorState.quantity_of_lables_per_meter_line.error
          })}
        </div>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} textAlign="left">
          Detalle
        </Divider>
        <div>
          {CustomTextField({
            value: newOrder.details.esp,
            label: 'Esp',
            type: 'number',
            name: 'details.esp',
            onChange: handleChange,
            helperText: orderErrorState.details.esp.message,
            error: orderErrorState.details.esp.error
          })}

          {CustomTextField({
            value: newOrder.details.track,
            label: 'Nro Pistas',
            type: 'number',
            name: 'details.track',
            onChange: handleChange,
            helperText: orderErrorState.details.track.message,
            error: orderErrorState.details.track.error
          })}

          {CustomTextField({
            value: newOrder.details.repetitions,
            label: 'Repeticiones',
            type: 'number',
            name: 'details.repetitions',
            onChange: handleChange,
            helperText: orderErrorState.details.repetitions.message,
            error: orderErrorState.details.repetitions.error
          })}

          {CustomTextField({
            value: newOrder.details.duplex,
            label: 'Double Faz',
            type: 'number',
            name: 'details.duplex',
            onChange: handleChange,
            helperText: orderErrorState.details.duplex.message,
            error: orderErrorState.details.duplex.error
          })}
        </div>
        <div>
          {CustomTextField({
            value: newOrder.details.treated,
            label: 'Tratada',
            type: 'string',
            name: 'details.treated',
            onChange: handleChange,
            helperText: orderErrorState.details.treated.message,
            error: orderErrorState.details.treated.error
          })}
        </div>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={cancelInsert}>
            Cancelar
          </Button>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleOrderCreation}>
            CREAR
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Order;
