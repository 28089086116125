import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import httpClient, {
  GetEndpointInjector,
  GetOneEndpointInjector,
  PostEndpointInjector,
  PutEndpointInjector
} from '../api/httpClient';
import { logout } from './authSlice';
import { handleErrorResponse } from '../utils/utils';
import { Options, Paginated, buildPagingOptions } from './paging';

export type ClientPost = {
  code: string;
  name: string;
  gov_id_type: string;
  gov_id: string;
  address: string;
};

export type ClientGet = {
  client_id: string;
  code: string;
  name: string;
  gov_id_type: string;
  gov_id: string;
  address: string;
};

const getAccessToken = () => {
  const val = sessionStorage.getItem('userInfo');

  if (val === undefined || val === null) {
    logout();
  }

  const jsonVal = JSON.parse(val!);

  return jsonVal.access_token;
};

export const postClient = createAsyncThunk(
  'clients/post',
  async (client: PostEndpointInjector<ClientPost>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.post(`/clients`, client.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: client.navigate });
      return err;
    }
  }
);

export const putClient = createAsyncThunk(
  'clients/put',
  async (client: PutEndpointInjector<ClientPost>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.put(`/clients/${client.id}`, client.body, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: client.navigate });
      return err;
    }
  }
);

export const getClients = createAsyncThunk(
  'clients/get/mul',
  async (deps: GetEndpointInjector<Options>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/clients?${buildPagingOptions(deps.options)}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

export const getClient = createAsyncThunk(
  'clients/get',
  async (item: GetOneEndpointInjector, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(`/clients/${item.id}`, {
        headers: { access_token: accessToken }
      });
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: item.navigate });
      return err;
    }
  }
);

export const searchClients = createAsyncThunk(
  'clients/search',
  async (deps: GetEndpointInjector<{ word: string }>, { dispatch }) => {
    try {
      const accessToken = getAccessToken();
      const response = await httpClient.get(
        `/search/clients?search_word=${deps.options?.word ?? ''}`,
        {
          headers: { access_token: accessToken }
        }
      );
      return response.data;
    } catch (error) {
      const err = handleErrorResponse({ error, dispatch, navigate: deps.navigate });
      return err;
    }
  }
);

type ErrorResponse = {
  message: string;
};

type ClientApiState = {
  clients?: ClientGet[] | null;
  client?: ClientGet | null;
  page: number;
  limit: number;
  count: number;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
};

const initialState: ClientApiState = {
  clients: undefined,
  status: 'idle',
  page: 1,
  limit: 10,
  count: 0,
  error: null
};

const clientSlide = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.client = undefined;
    },
    setLimit(state, action: PayloadAction<number>) {
      console.log(action.payload);
      state.limit = action.payload;
      state.page = 1;
      state.client = undefined;
    },
    resetClientState(state) {
      state.client = undefined;
      state.clients = undefined;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getClients.fulfilled, (state, action: PayloadAction<Paginated<ClientGet>>) => {
        state.status = 'idle';
        state.clients = action.payload.items;
        state.count = action.payload.count;
      })
      .addCase(getClients.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(postClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(postClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(putClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(putClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = undefined;
        state.client = undefined;
      })
      .addCase(putClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(getClient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.status = 'idle';
        state.client = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      })
      .addCase(searchClients.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(searchClients.fulfilled, (state, action: PayloadAction<ClientGet[]>) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(searchClients.rejected, (state, action) => {
        state.status = 'failed';

        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Login failed';
        } else {
          state.error = action.error.message || 'Login failed';
        }
      });
  }
});

export const { resetClientState } = clientSlide.actions;
export default clientSlide.reducer;
export const { setPage } = clientSlide.actions;
export const { setLimit } = clientSlide.actions;
